import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '@lib/components';
import { TColumn, TColumnSort } from '@lib/modules/table/components';
import { ApiResponseError, ICashbackIndex, ISearchBooleanQueryMust, ISearchCashbackResponse, Nullable } from '@lib/interfaces';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CardManagementService } from '@lib/services';
import { NgOptimizedImage } from '@angular/common';
import { TableModule } from '@lib/modules';
import { AngularMaterialModule } from '../../../../material.module';
import { MessageTypePipe, OptionalPlaceholderPipe, SourceChannelPipe, TransactionStatusPipe, TransactionTypePipe } from '@lib/pipes';
import { finalize } from 'rxjs';
import { TransactionIconComponent } from '@lib/svg';
import { ICursorForm, ISearchBooleanQueryForm, ISearchBooleanQueryMustFormArray, ISearchCashbackWalletRequestForm,TransactionSearchService } from '@pages/transaction/services';

@Component({
    standalone: true,
    selector: 'app-cashback',
    templateUrl: './cashback.component.html',
    styleUrls: ['./cashback.component.scss'],
    imports: [AngularMaterialModule, TableModule, TransactionStatusPipe, OptionalPlaceholderPipe, NgOptimizedImage, TransactionTypePipe, TransactionIconComponent, MessageTypePipe, SourceChannelPipe],
})
export class CashbackComponent extends BaseComponent implements OnInit  {

    @Input() public filters: Array<ISearchBooleanQueryMust> = [];
    @Input() public isOpenedFromCardOverview = true;

    protected columns: Array<TColumn> = [
        {fee_amount: 'Fee Amount' },
        {from_amount:'From Amount'},
        {from_currency: 'From Currency'},
        {id: 'ID'},
        {parent_transaction_id:'Parent Transaction ID'},
        {parent_transaction_type: 'Parent Transaction Type' },
        {recipient_account_id: 'Recipient Account ID'},
        {recipient_user_id: 'Recipient User ID'},
        {rrn:'RRN'},
        {status_code: 'Status Code' },
        {to_amount: 'To Amount'},
        {to_currency: 'To Currency' },
        {transaction_timestamp: 'Date & Time' },
        {type: 'Type' },
    ];
    protected defaultColumns: Array<string> = ['transaction_timestamp','type','parent_transaction_id','parent_transaction_type','recipient_account_id','status_code'];
    protected sortableColumns: Array<string> = [];
    protected cashbacks: Array<ICashbackIndex> = [];
    protected hasMoreRecords = true;
    protected loading = false;
    protected cashbackHeading: string;
    private apiInProgress = false;
    private searchPayload: FormGroup<ISearchCashbackWalletRequestForm> = this.createSearchCashbackFormGroup();

    public constructor(
        private readonly formBuilder: FormBuilder,
        private readonly matSnackBar: MatSnackBar,
        private readonly cardManagementService: CardManagementService,
        private readonly transactionSearchService: TransactionSearchService,
    ) {
        super();
    }

    public ngOnInit(): void {
        this.cashbackHeading =  `Cash Back`;
        this.applyBuiltInFilters();
        this.reFetchCashback();
    }

    protected searchCashbacks(): void {
        if (this.apiInProgress) return;

        this.apiInProgress = true;

        this.addSubscription(
            this.cardManagementService
                .searchCashback(this.searchPayload.value)
                .pipe(
                    finalize((): void => {
                        this.loading = false;
                        this.apiInProgress = false;
                    }),
                )
                .subscribe({
                    next: ({ data, paging }: ISearchCashbackResponse): void => {
                        if (!this.isOpenedFromCardOverview) {
                            this.cashbacks = data.slice(0, 5);
                            this.hasMoreRecords = false;
                        } else {
                            this.cashbacks = [...this.cashbacks, ...data];

                            if (!paging) {
                                this.hasMoreRecords = false;

                                return;
                            }
                            this.searchPayload.controls.cursors?.controls.after?.setValue(paging.cursors.after);
                        }
                    },
                    error: (error: ApiResponseError): void => {
                        this.hasMoreRecords = false;

                        this.createApiErrorSnackBar(this.matSnackBar, error);
                    },
                }),
        );
    }

    protected applySort(sortBy: Nullable<TColumnSort>): void {
        this.clearCashbacks();
        if(sortBy){
            this.searchPayload.controls.sort_by?.setValue(sortBy.key);
            this.searchPayload.controls.order_by?.setValue(sortBy.sort);
        }
    }

    private reFetchCashback(): void {
        this.loading = true;
        this.hasMoreRecords = true;

        this.searchCashbacks();
    }

    private clearCashbacks(): void {
        this.searchPayload.controls.cursors?.setValue({
            after:''
        });
        this.cashbacks = [];
    }

    private createSearchCashbackFormGroup(): FormGroup<ISearchCashbackWalletRequestForm> {
        return this.formBuilder.group<ISearchCashbackWalletRequestForm>({
            cursors:this.formBuilder.group<ICursorForm>({
                after:this.formBuilder.nonNullable.control<string>('')
            }),
            limit: this.formBuilder.nonNullable.control<number>(10),
            sort_by: this.formBuilder.nonNullable.control<string>('transaction_timestamp'),
            order_by: this.formBuilder.nonNullable.control<string>('desc'),
            boolean_query:this.formBuilder.group<ISearchBooleanQueryForm>({
                must:this.formBuilder.array<ISearchBooleanQueryMustFormArray>([])
            })
        });
    }

    private applyBuiltInFilters(): void {
        this.filters.forEach((filter:ISearchBooleanQueryMust) => {
            this.transactionSearchService.addCashbackWalletFilter(this.searchPayload,filter);
        });
    }
}